import { Button, Dropdown, Layout, Menu, message } from "antd";
import AxiosInstance from "../utils/AxiosInstance";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  DashboardOutlined,
  CopyOutlined,
  GroupOutlined,
  UngroupOutlined,
  ShopOutlined,
  InboxOutlined,
  TransactionOutlined,
  DropboxCircleFilled,
  DownOutlined,
  DesktopOutlined,
  KeyOutlined,
} from "@ant-design/icons";

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import Logo from "../img/logo.png";
import "./layouts.scss";
import Avatar from "antd/lib/avatar/avatar";
import { isLoggedIn, removeToken } from "../utils/token";

import { setUser } from "../redux/user/userSlice";
import { useSelector, useDispatch } from "react-redux";

const { Header, Sider, Content } = Layout;

export default function AdminLayout({ children }) {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);
  const history = useLocation();

  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [userRoutes, setUserRoutes] = useState([]);

  const toggle = () => {
    setCollapsed((prev) => !prev);
  };

  const getuserDetails = async () => {
    await AxiosInstance("api/users/getUserDetails")
      .then((res) => {
        console.log(res.data);
        if (res.data.success) {
          console.log("its In");
          mapLayoutData(res.data.data.user_role_id);
          dispatch(setUser(res.data.data));
        } else {
          message.error("Something went wrong, Please try again");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const findSelectedIndex = (selectedName) => {
    const menuArray = [
      "/",
      "/Users",
      "/Invoices",
      "/Categories",
      "/SubCategories",
      "/Shops",
      "/Pos",
      "/Ads",
      "/AdsTemplates",
      "/Packages",
      "/Subscriptions",
      "/Licenses",
      "/Password",
    ];
    let index = menuArray.indexOf(selectedName);
    return (index + 1).toString();
  };

  const handleLogOut = () => {
    removeToken();
    dispatch(setUser(null));
    navigate("/login", { replace: true });
  };
  const mapLayoutData = (id) => {
    let data = [];

    if (id == 3) {
      data = [
        "/Dashboard",
        "/Users",
        "/Invoices",
        "/Categories",
        "/SubCategories",
        "/Shops",
        "/POS",
        "/Ads",
        "/AdsTemplates",
        "/Packages",
        "/Subscriptions",
        "/Licenses",
        "/Promotions",
        "/Password",
      ];
    } else {
      data = [
        "/Dashboard",
        "/Users",
        "/Invoices",
        "/Shops",
        "/POS",
        "/Ads",
        "/Subscriptions",
        "/Password",
      ];
    }
    setUserRoutes(data);
  };

  useEffect(() => {
    console.log("user routes : ", userRoutes);

    if (isLoggedIn()) {
      if (!user) {
        getuserDetails();
      }
      mapLayoutData(user && user.user_role_id);
      console.log("Redux User", user);
    } else {
      navigate("/login", { replace: true });
    }
  }, []);
  // console.log(userRoutes);
  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed} theme="light">
        {!collapsed && (
          <div className="d-flex justify-content-center py-3 bg-white">
            <img src={Logo} alt="" width={80} />
          </div>
        )}

        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={[findSelectedIndex(history.pathname)]}
        >
          {userRoutes && userRoutes.includes("/Dashboard") === true ? (
            <Menu.Item
              key="1"
              icon={<DashboardOutlined />}
              onClick={() => navigate("/", { replace: true })}
            >
              Dashboard
            </Menu.Item>
          ) : null}

          {userRoutes && userRoutes.includes("/Users") === true ? (
            <Menu.Item
              key="2"
              icon={<UserOutlined />}
              onClick={() => navigate("/Users", { replace: true })}
            >
              Users
            </Menu.Item>
          ) : null}

          {userRoutes && userRoutes.includes("/Invoices") === true ? (
            <Menu.Item
              key="3"
              icon={<CopyOutlined />}
              onClick={() => navigate("/Invoices", { replace: true })}
            >
              Invoices
            </Menu.Item>
          ) : null}

          {userRoutes && userRoutes.includes("/Categories") === true ? (
            <Menu.Item
              key="4"
              icon={<GroupOutlined />}
              onClick={() => navigate("/Categories", { replace: true })}
            >
              Categories
            </Menu.Item>
          ) : null}

          {userRoutes && userRoutes.includes("/SubCategories") === true ? (
            <Menu.Item
              key="5"
              icon={<UngroupOutlined />}
              onClick={() => navigate("/SubCategories", { replace: true })}
            >
              Sub Categories
            </Menu.Item>
          ) : null}

          {userRoutes && userRoutes.includes("/Shops") === true ? (
            <Menu.Item
              key="6"
              icon={<ShopOutlined />}
              onClick={() => navigate("/Shops", { replace: true })}
            >
              Shops
            </Menu.Item>
          ) : null}

          {userRoutes && userRoutes.includes("/POS") === true ? (
            <Menu.Item
              key="7"
              icon={<DesktopOutlined />}
              onClick={() => navigate("/Pos", { replace: true })}
            >
              POS
            </Menu.Item>
          ) : null}

          {userRoutes && userRoutes.includes("/Ads") === true ? (
            <Menu.Item
              key="8"
              icon={<ShopOutlined />}
              onClick={() => navigate("/Ads", { replace: true })}
            >
              Ads
            </Menu.Item>
          ) : null}
          {userRoutes && userRoutes.includes("/AdsTemplates") === true ? (
            <Menu.Item
              key="9"
              icon={<ShopOutlined />}
              onClick={() => navigate("/AdsTemplates", { replace: true })}
            >
              Ads Templates
            </Menu.Item>
          ) : null}

          {userRoutes && userRoutes.includes("/Packages") === true ? (
            <Menu.Item
              key="10"
              icon={<InboxOutlined />}
              onClick={() => navigate("/Packages", { replace: true })}
            >
              Packages
            </Menu.Item>
          ) : null}

          {userRoutes && userRoutes.includes("/Subscriptions") === true ? (
            <Menu.Item
              key="11"
              icon={<TransactionOutlined />}
              onClick={() => navigate("/Subscriptions", { replace: true })}
            >
              Subscriptions
            </Menu.Item>
          ) : null}

          {userRoutes && userRoutes.includes("/Licenses") === true ? (
            <Menu.Item
              key="12"
              icon={<KeyOutlined />}
              onClick={() => navigate("/Licenses", { replace: true })}
            >
              Licenses
            </Menu.Item>
          ) : null}
          {useRoutes && userRoutes.includes("/Promotions") === true ? (
            <Menu.Item
              key="13"
              icon={<KeyOutlined />}
              onClick={() => navigate("/Promotions", { replace: true })}
            >
              Promotions
            </Menu.Item>
          ) : null}
          {userRoutes && userRoutes.includes("/Password") === true ? (
            <Menu.Item
              key="14"
              icon={<KeyOutlined />}
              onClick={() => navigate("/Password", { replace: true })}
            >
              Change Password
            </Menu.Item>
          ) : null}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{ paddingLeft: 20, paddingRight: 30 }}
        >
          <div className="container-fluid p-0 m-0">
            <div className="row justify-content-between">
              <div className="col-2">
                {React.createElement(
                  collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: "trigger",
                    onClick: toggle,
                  }
                )}
              </div>
              <div className="col-3 d-flex justify-content-end align-items-center">
                <div className="d-flex align-items-center">
                  <div style={{ paddingRight: 8, textTransform: "capitalize" }}>
                    {user && user.user_name}
                  </div>

                  <Avatar
                    style={{
                      backgroundColor: "#fde3cf",
                      verticalAlign: "middle",
                      cursor: "pointer",
                    }}
                    size="large"
                  >
                    U
                  </Avatar>
                  <Button className="mx-2" type="danger" onClick={handleLogOut}>
                    Logout
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 1000,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
}
